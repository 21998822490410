import * as S from "./InfoBox.styled";
import { logo } from "../../assets";
import { CustomTooltip } from "../Tooltip/CustomTooltip";

export type InfoBoxProps = {
  title?: string;
  children: React.ReactNode;
  single?: boolean;
  withIcon?: boolean;
  awarded?: boolean;
  center?: boolean;
  propertyDescription?: string;
};

export const Primary = ({
  title,
  children,
  single,
  withIcon,
  awarded,
  center,
  propertyDescription,
}: InfoBoxProps) => (
  <S.WrapperPrimary single={single} awarded={awarded} center={center}>
    {(title || withIcon) && (
      <S.Container>
        {withIcon && <S.Image src={logo} alt="logo" />}
        {title && (
          <S.Title uppercase as="h3" fontWeight={400}>
            {title}
          </S.Title>
        )}
        {propertyDescription && <CustomTooltip message={propertyDescription} />}
      </S.Container>
    )}
    <S.ContentPrimary center={center}>{children}</S.ContentPrimary>
  </S.WrapperPrimary>
);

export const Secondary = ({
  title,
  children,
  single,
  withIcon,
  awarded,
  center,
  propertyDescription,
}: InfoBoxProps) => (
  <S.WrapperSecondary single={single} awarded={awarded} center={center}>
    {(title || withIcon) && (
      <S.Container>
        {withIcon && <S.Image src={logo} alt="logo" />}
        {title && (
          <S.Title uppercase as="h3">
            {title}
          </S.Title>
        )}
        {propertyDescription && <CustomTooltip message={propertyDescription} />}
      </S.Container>
    )}
    <S.ContentSecondary>{children}</S.ContentSecondary>
  </S.WrapperSecondary>
);

export const Admin = ({
  title,
  children,
  single,
  withIcon,
  awarded,
  center,
  propertyDescription,
}: InfoBoxProps) => (
  <S.WrapperAdmin single={single} awarded={awarded} center={center}>
    {(title || withIcon) && (
      <S.Container>
        {withIcon && <S.Image src={logo} alt="logo" />}
        {title && <S.AdminTitle as="h3">{title}</S.AdminTitle>}
        {propertyDescription && <CustomTooltip message={propertyDescription} />}
      </S.Container>
    )}
    <S.ContentSecondary>{children}</S.ContentSecondary>
  </S.WrapperAdmin>
);

export const InfoBox = {
  Primary,
  Secondary,
  Admin,
};
