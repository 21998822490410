import { useAccount } from "wagmi";

interface IPoolsV2Addresses {
  [key: number]: string | undefined;
}
const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string
) => {
  if (typeof variable !== "string") {
    throw Error(
      `You need to include all necessary variables inside .env file in the root of your project to run application. First missing variable: ${expectedVariableName}.`
    );
  }
  return variable;
};
const PROTOCOL_SETTINGS_ADDRESS: IPoolsV2Addresses = {
  // Mainnets
  137: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_POLYGON,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_POLYGON"
  ), // Polygon
  1: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ETHEREUM,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ETHEREUM"
  ), // Ethereum

  // Testnets
  11155111: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ETHEREUM,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ETHEREUM"
  ), // Ethereum Sepolia
  80002: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_POLYGON,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_POLYGON"
  ), // Polygon Amoy Testnet

  42161: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ARBITRUM,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ARBITRUM"
  ), // Arbitrum
  421614: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ARBITRUM,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_ARBITRUM"
  ), // Arbitrum Sepolia
  8453: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BASE,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BASE"
  ), // Base
  84532: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BASE,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BASE"
  ), // Base Sepolia
  43114: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_AVALANCHE,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_AVALANCHE"
  ), // Avalanche
  43113: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_AVALANCHE,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_AVALANCHE"
  ), // Avalanche Fuji
  10: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_OPTIMISM,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_OPTIMISM"
  ), // Optimism
  11155420: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_OPTIMISM,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_OPTIMISM"
  ), // Optimism Sepolia
  56: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BINANCE,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BINANCE"
  ), // Binance
  97: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BINANCE,
    "REACT_APP_PROTOCOL_SETTINGS_CONTRACT_BINANCE"
  ), // Binance Smart Chain Testnet
};

export const useProtocolSettingsAddress = () => {
  const { chainId } = useAccount();

  if (!chainId || !PROTOCOL_SETTINGS_ADDRESS[chainId]) {
    return envVariableValidator(
      process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON_V2,
      "REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON"
    );
  }

  return String(PROTOCOL_SETTINGS_ADDRESS[chainId]);
};
