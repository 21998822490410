/* eslint-disable @typescript-eslint/no-explicit-any */
import { ParamType } from "@ethersproject/abi";
import { ethers } from "ethers";
import { Abi } from "./abi";

const ABI = [
  {
    constant: true,
    inputs: [],
    name: "getCurrentBlockTimestamp",
    outputs: [{ name: "timestamp", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        components: [
          { name: "target", type: "address" },
          { name: "callData", type: "bytes" },
        ],
        name: "calls",
        type: "tuple[]",
      },
    ],
    name: "aggregate",
    outputs: [
      { name: "blockNumber", type: "uint256" },
      { name: "returnData", type: "bytes[]" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getLastBlockHash",
    outputs: [{ name: "blockHash", type: "bytes32" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "addr", type: "address" }],
    name: "getEthBalance",
    outputs: [{ name: "balance", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getCurrentBlockDifficulty",
    outputs: [{ name: "difficulty", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getCurrentBlockGasLimit",
    outputs: [{ name: "gaslimit", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getCurrentBlockCoinbase",
    outputs: [{ name: "coinbase", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "blockNumber", type: "uint256" }],
    name: "getBlockHash",
    outputs: [{ name: "blockHash", type: "bytes32" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const multicallAddresses: { [key: number]: string } = {
  1: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
  3: "0xF24b01476a55d635118ca848fbc7Dab69d403be3",
  4: "0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821",
  5: "0x77dca2c955b15e9de4dbbcf1246b4b85b651e50e",
  10: "0xcA11bde05977b3631167028862bE2a173976CA11",
  42: "0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a",
  56: "0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb",
  66: "0x94fEadE0D3D832E4A05d459eBeA9350c6cDd3bCa",
  97: "0x3A09ad1B8535F25b48e6Fa0CFd07dB6B017b31B2",
  100: "0xb5b692a88bdfc81ca69dcb1d924f59f0413a602a",
  128: "0x2C55D51804CF5b436BA5AF37bD7b8E5DB70EBf29",
  137: "0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507",
  250: "0x0118EF741097D0d3cc88e46233Da1e407d9ac139",
  1337: "0x77dca2c955b15e9de4dbbcf1246b4b85b651e50e",
  42161: "0xcA11bde05977b3631167028862bE2a173976CA11",
  43114: "0x7f3aC7C283d7E6662D886F494f7bc6F1993cDacf",
  43113: "0xcA11bde05977b3631167028862bE2a173976CA11",
  80001: "0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc",
  80002: "0xcA11bde05977b3631167028862bE2a173976CA11",
  7672: "0xEcFbBb8E6f8CcF03C9AdFd0249881E51C61b4B18",
  7668: "0x126FD3f8BcEe20790974AEbf05e67373967A9050",
  11155111: "0xcA11bde05977b3631167028862bE2a173976CA11",
  11155420: "0xcA11bde05977b3631167028862bE2a173976CA11",
  421614: "0xcA11bde05977b3631167028862bE2a173976CA11",
  84532: "0xcA11bde05977b3631167028862bE2a173976CA11",
  8453: "0xca11bde05977b3631167028862be2a173976ca11",
};

export interface ContractCall {
  contract: {
    address: string;
  };
  name: string;
  inputs: ParamType[];
  outputs: ParamType[];
  params: any[];
}

/**
 * Multicall
 * @param provider ethersjs web3 provider
 * @param calls array of calls
 * @returns array of responses
 */
export const multicall = async <T extends any[] = any[]>(
  provider: ethers.providers.BaseProvider,
  calls: ContractCall[]
): Promise<T> => {
  if (!provider) throw new Error("Provider should be initialized before use");
  const { chainId } = await provider.getNetwork();
  const multicallAddress = multicallAddresses[chainId];
  if (!multicallAddress)
    throw new Error(`Multicall unsupported for chainId: ${chainId}`);

  const multiCallContract = new ethers.Contract(
    multicallAddress,
    ABI,
    provider
  );
  const callRequests = calls.map((call) => {
    const callData = Abi.encode(call.name, call.inputs, call.params);
    return {
      target: call.contract.address,
      callData,
    };
  });
  const response = await multiCallContract.callStatic.aggregate(callRequests);
  const callCount = calls.length;
  const callResult = [] as unknown as T;
  for (let i = 0; i < callCount; i += 1) {
    const { outputs } = calls[i];
    const returnData = response.returnData[i];
    const params = Abi.decode(outputs, returnData);
    const result = outputs.length === 1 ? params[0] : params;
    callResult.push(result);
  }
  return callResult;
};

export default multicall;
