import { Typography, Button } from "../components";
import * as S from "./ToastBody.styled";

interface ChainData {
  transactionHash?: string | string[];
  chainId: number;
}

type ToastBodyProps = {
  title: string;
  message: string;
  chainData?: ChainData;
};

const getExplorerLinkByChain = (chainData: ChainData) => {
  switch (chainData.chainId) {
    case 1:
      return `https://etherscan.io/tx/${chainData.transactionHash}`;
    case 137:
      return `https://polygonscan.com/tx/${chainData.transactionHash}`;
    case 80002:
      return `https://amoy.polygonscan.com/tx/${chainData.transactionHash}`;
    case 11155111:
      return `https://sepolia.etherscan.io/tx/${chainData.transactionHash}`;
    case 42161:
      return `https://arbiscan.io/tx/${chainData.transactionHash}`;
    case 421614:
      return `https://sepolia.arbiscan.io/tx/${chainData.transactionHash}`;
    case 8453:
      return `https://basescan.org/tx/${chainData.transactionHash}`;
    case 84532:
      return `https://sepolia.basescan.org/tx/${chainData.transactionHash}`;
    case 43114:
      return `https://avascan.info/blockchain/pulsar/tx/${chainData.transactionHash}`;
    case 43113:
      return `https://testnet.avascan.info/blockchain/pulsar/tx/${chainData.transactionHash}`;
    case 10:
      return `https://optimistic.etherscan.io/tx/${chainData.transactionHash}`;
    case 11155420:
      return `https://sepolia-optimism.etherscan.io/tx/${chainData.transactionHash}`;
    case 56:
      return `https://bscscan.com/tx/${chainData.transactionHash}`;
    case 97:
      return `https://testnet.bscscan.com/tx/${chainData.transactionHash}`;
    default:
      return "";
  }
};

const ToastBody = ({ title, message, chainData }: ToastBodyProps) => (
  <S.ToastBodyWrapper>
    <Typography.ToastTitle fontWeight={400}>{title}</Typography.ToastTitle>
    <Typography.ToastMessage isWhite fontWeight={400} opacity={0.9}>
      {message}
    </Typography.ToastMessage>
    {chainData?.transactionHash &&
    typeof chainData?.transactionHash === "string" ? (
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <a
          href={getExplorerLinkByChain(chainData)}
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          <Button.Primary
            type="button"
            style={{ width: "150px", marginTop: "10px" }}
          >
            check details
          </Button.Primary>
        </a>
      </div>
    ) : (
      Array.isArray(chainData?.transactionHash) &&
      chainData?.transactionHash.length > 0 &&
      chainData?.transactionHash.map((hash) => (
        <div
          key={hash}
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <a
            href={getExplorerLinkByChain({
              ...chainData,
              transactionHash: hash,
            })}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            <Button.Primary
              type="button"
              style={{ width: "150px", marginTop: "10px" }}
            >
              check details
            </Button.Primary>
          </a>
        </div>
      ))
    )}
  </S.ToastBodyWrapper>
);

export default ToastBody;
