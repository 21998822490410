import { useAccount } from "wagmi";

interface IUsdcAddresses {
  [key: number]: string | undefined;
}
const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string
) => {
  if (typeof variable !== "string") {
    throw Error(
      `You need to include all necessary variables inside .env file in the root of your project to run application. First missing variable: ${expectedVariableName}.`
    );
  }
  return variable;
};
const USDC_ADDRESSES: IUsdcAddresses = {
  // Mainnets
  137: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_POLYGON,
    "USDC_TOKEN_ADDRESS_Polygon"
  ), // Polygon
  1: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_ETHEREUM,
    "USDC_TOKEN_ADDRESS_ETH"
  ), // Ethereum
  42161: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_ARBITRUM,
    "USDC_TOKEN_ADDRESS_ARBITRUM"
  ), // Arbitrum
  8453: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_BASE,
    "USDC_TOKEN_ADDRESS_BASE"
  ), // Base

  // Testnets
  11155111: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_ETHEREUM,
    "USDC_TOKEN_ADDRESS_ETH_TESTNET"
  ), // Ethereum Sepolia
  421614: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_ARBITRUM,
    "USDC_TOKEN_ADDRESS_ARBITRUM_TESTNET"
  ), // Arbitrum Sepolia
  80002: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_POLYGON,
    "USDC_TOKEN_ADDRESS_POLYGON_AMOY_TESTNET"
  ), // Polygon Amoy Testnet
  84532: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_BASE,
    "USDC_TOKEN_ADDRESS_BASE_TESTNET"
  ), // Base Sepolia
  43114: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_AVALANCHE,
    "USDC_TOKEN_ADDRESS_AVALANCHE"
  ), // Avalanche
  43113: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_AVALANCHE,
    "USDC_TOKEN_ADDRESS_AVALANCHE_TESTNET"
  ), // Avalanche Fuji
  10: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_OPTIMISM,
    "USDC_TOKEN_ADDRESS_OPTIMISM"
  ), // Optimism
  11155420: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_OPTIMISM,
    "USDC_TOKEN_ADDRESS_OPTIMISM_TESTNET"
  ), // Optimism Sepolia
  56: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_BINANCE,
    "USDC_TOKEN_ADDRESS_BINANCE"
  ), // Binance Smart Chain
  97: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_BINANCE,
    "USDC_TOKEN_ADDRESS_BINANCE_TESTNET"
  ), // Binance Smart Chain Testnet
};

export const useUsdcTokenAddress = () => {
  const { chainId } = useAccount();

  if (!chainId || !USDC_ADDRESSES[chainId]) {
    return envVariableValidator(
      process.env.REACT_APP_USDC_TOKEN_ADDRESS_POLYGON,
      "USDC_TOKEN_ADDRESS_Polygon"
    );
  }

  return String(USDC_ADDRESSES[chainId]);
};
