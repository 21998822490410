import qs from "qs";
import { apiCallWithAuthToken } from "./config";

type ReferralResponse = {
  id: number;
  code: string;
  createdAt: string;
  updatedAt: string;
};

type UserReferralStatsResponse = {
  referredTotal: number;
  totalEarnings: string;
  unpaidEarnings: string;
  paidEarnings: string;
};

export type GlobalReferralStatsResponse = {
  numberOfReflinks: number;
} & UserReferralStatsResponse;

export const createRefcode = () =>
  apiCallWithAuthToken.post<ReferralResponse>(`reflink`);

export const getUserRefcode = () =>
  apiCallWithAuthToken.get<ReferralResponse>(`reflink`);

export const getUserReferralStats = () =>
  apiCallWithAuthToken.get<UserReferralStatsResponse>(`reflink/my-stats`);

export type UserBonusType = {
  id: number;
  amount: string;
  createdAt: string;
  updatedAt: string;
  status: "PAID" | "IDLE";
  currency: string;
};

export type BonusToWithdraw = {
  walletAddress: string;
  userId: number;
  currency: string;
  totalbonuses: string;
};

type UserBonusResponse = {
  data: UserBonusType[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

type TotalBonusesResponse = {
  data: BonusToWithdraw[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

type FilteredKeys = "id" | "amount" | "walletAddress" | "createdAt" | "status";

export const getUserBonuses = (params: GetQueryParams<FilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<UserBonusResponse>(
    `bonus/my-history?${queryString}`
  );
};

export const getUserReferralsData = (
  id: number,
  params: GetQueryParams<FilteredKeys>
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<UserBonusResponse>(
    `bonus/user/${id}/history?${queryString}`
  );
};

export const getBonusGlobalStats = () =>
  apiCallWithAuthToken.get<GlobalReferralStatsResponse>(`reflink/stats`);

export const getBonusesToWithdraw = (params: GetQueryParams<FilteredKeys>) =>
  apiCallWithAuthToken.get<TotalBonusesResponse>("bonus");

export const withdrawBonus = (userId: number) =>
  apiCallWithAuthToken.post(`bonus/withdraw`, { userId: userId.toString() });
