import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { Button } from "../Button/Button.styled";
import { device } from "../../style";

export const ConnectWalletWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  p {
    font-weight: 300;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const CustomButton = styled(Button.Primary)<{ isLoading?: boolean }>`
  border-radius: 21px;
  height: 68px;
  width: 100%;
  border: 1px solid #000;
  background: transparent;
  color: #000;
  padding: 10px;
  position: relative;

  &:hover {
    background: transparent;
    border-color: #08a98a;
  }

  @media ${device.tablet} {
    max-width: 482px;
  }

  &:disabled {
    background-color: transparent;
    opacity: 1;
    transition: all 0.2s linear;

    &::after {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      opacity: 0.9;
      border-radius: 21px;
      transform: none;
      animation: none;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: transparent;
      border-top: 3px solid #000;
      display: ${({ isLoading }) => (isLoading ? "block" : "none")};
      animation: ${spin} 1s linear infinite;
      opacity: 1;
      z-index: 2;
    }
  }
`;
