import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAccount, useSwitchChain } from "wagmi";
import { Chain } from "wagmi/chains";
import {
  arbitrumLogo,
  avalancheLogo,
  baseLogo,
  bscLogo,
  cursor,
  ethereumLogo,
  metamaskIcon,
  optimismLogo,
  polygonLogo,
  smallArrowDown,
  walletconnectIcon,
} from "../../../../../../assets";
import { Modal, Typography } from "../../../../../../components";
import { configWagmi } from "../../../../../../context/WagmiProvider";
import {
  useDetectOutsideClick,
  useMediaQuery,
  useUsdcTokenAddress,
} from "../../../../../../hooks";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux";
import {
  invalidatePoolData,
  setChainId,
} from "../../../../../../redux/features/uiSlice";
import { device } from "../../../../../../style";
import { toast } from "../../../../../../toasts";
import Dropdown from "../Dropdown/Dropdown";

interface NetworkSwitcherProps {
  isOpen: boolean;
  toggleVisible: () => void;
}

export const NetworkSwitcher = ({
  isOpen,
  toggleVisible,
}: NetworkSwitcherProps) => {
  const {
    isConnected,
    connector,
    address,
    chainId: wagmiChainId,
  } = useAccount();
  const dispatch = useAppDispatch();
  const { switchChain, isPending, isSuccess, isError } = useSwitchChain();
  const iconBtn = document.getElementsByClassName("ignoreOutsideClick");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const usdcTokenAddress = useUsdcTokenAddress();
  const isDesktopView = useMediaQuery(device.mobileXL);
  const appChainId = useAppSelector((state) => state.ui.chainId);
  const [chainToName, setChainToName] = useState<string>(
    configWagmi.chains.find(
      (chain) => chain.id === (wagmiChainId ?? appChainId)
    )?.name ?? ""
  );

  const chainId = wagmiChainId ?? appChainId;

  useDetectOutsideClick(dropdownRef, toggleVisible, iconBtn);

  const getNetworkLogo = (id: number) => {
    switch (id) {
      case 1:
        return ethereumLogo;
      case 11155111:
        return ethereumLogo;
      case 42161:
        return arbitrumLogo;
      case 421614:
        return arbitrumLogo;
      case 8453:
        return baseLogo;
      case 84532:
        return baseLogo;
      case 137:
        return polygonLogo;
      case 80002:
        return polygonLogo;
      case 43114:
        return avalancheLogo;
      case 43113:
        return avalancheLogo;
      case 10:
        return optimismLogo;
      case 11155420:
        return optimismLogo;
      case 56:
        return bscLogo;
      case 97:
        return bscLogo;
      default:
        return ethereumLogo;
    }
  };

  useEffect(() => {
    if (chainId) {
      setChainToName(
        configWagmi.chains.find((chain) => chain.id === chainId)?.name ?? ""
      );
    }

    if (isSuccess && chainId) {
      if (usdcTokenAddress && address)
        dispatch(actions.wallet.getBalances({ address, chainId }));
      dispatch(invalidatePoolData());
      toast.success(
        "Successful switch",
        "The network has been successfully switched"
      );
    }
    if (isError) {
      toast.error(
        "Switch network unsuccessful",
        "Network switch to Polygon Mainnet failed"
      );
    }
  }, [isError, isSuccess, usdcTokenAddress, chainId]);

  return chainId ? (
    <NetworkSwitcherContainer>
      <NetworkSwitcherWrapper className="ignoreOutsideClick">
        <NetworkButton onClick={toggleVisible} isOpen={isOpen}>
          <NetworkLogo src={getNetworkLogo(chainId)} />
          {isDesktopView && (
            <Typography.BarUSDCLabel style={{ color: "#FFF" }} fontWeight={500}>
              {chainToName} Network
            </Typography.BarUSDCLabel>
          )}
          <NetworkLogo isOpen={isOpen} src={smallArrowDown} alt="arrow down" />
        </NetworkButton>
      </NetworkSwitcherWrapper>
      {isOpen ? (
        <Dropdown innerRef={dropdownRef} targetIcon="network">
          <DropdownWrapper>
            <Typography.Body fontWeight={300} opacity={0.92}>
              Select network
            </Typography.Body>
            <ChainsWrapper>
              {configWagmi.chains?.map((configChain: Chain) => (
                <ChainItem
                  active={configChain?.id === chainId}
                  onClick={() => {
                    if (isConnected) {
                      switchChain?.(
                        { chainId: configChain.id },
                        {
                          onSuccess: () => {
                            dispatch(setChainId(configChain.id));
                          },
                        }
                      );
                    } else {
                      dispatch(setChainId(configChain.id));
                    }
                    setChainToName(configChain?.name);
                    toggleVisible();
                  }}
                >
                  <ChainIcon src={getNetworkLogo(configChain.id)} />
                  <Typography.Body fontWeight={400} opacity={0.92}>
                    {configChain.name}
                  </Typography.Body>
                </ChainItem>
              ))}
            </ChainsWrapper>
          </DropdownWrapper>
        </Dropdown>
      ) : null}
      <Modal
        title={["Network switch to ", chainToName]}
        description={
          connector?.name === "MetaMask"
            ? [
                "To continue using the application, you need to approve the network switch request in your MetaMask extension.",
                'Please click the MetaMask icon in your browser, review the request details, and hit "Confirm" to proceed.',
                "Make sure the selected network matches the one required by the app. If you have any questions, check the MetaMask documentation or contact our support.",
              ]
            : [
                "To complete the network switch, please approve the request in your wallet app that you use with WalletConnect.",
                'Open your wallet app on your mobile device, look for the network switch notification, and review the details. Once ready, click "Confirm" to continue.',
                "For assistance, refer to your wallet documentation or reach out to our support team.",
              ]
        }
        isOpen={isPending}
        onClose={() => null}
        withoutCloseIcon
      >
        <AnimatePresence>
          {isPending && (
            <AnimationWrapper>
              <motion.img
                width={70}
                height={70}
                src={
                  connector?.name === "MetaMask"
                    ? metamaskIcon
                    : walletconnectIcon
                }
                alt="metamask icon"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                transition={{
                  repeat: Infinity,
                  duration: 0.2,
                  repeatDelay: 3,
                }}
              />
              <motion.img
                src={cursor}
                alt="cursor"
                width={20}
                height={20}
                initial={{ x: "-80px" }}
                animate={{ x: 0 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "mirror",
                  duration: 1.6,
                }}
              />
            </AnimationWrapper>
          )}
        </AnimatePresence>
      </Modal>
    </NetworkSwitcherContainer>
  ) : null;
};

const NetworkSwitcherContainer = styled.div`
  flex: 1 0 auto;

  @media ${device.tablet} {
    position: relative;
  }
`;

const NetworkSwitcherWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
`;

const NetworkButton = styled.button<{ isOpen?: boolean }>`
  padding: 4px;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 2px;
  border: 1px solid ${({ isOpen }) => (isOpen ? "#08A98A" : "#FFFFFF54")};
  border-radius: 69px;
`;

const NetworkLogo = styled.img<{ isOpen?: boolean }>`
  width: 29px;
  height: 29px;
  position: relative;
  z-index: 1;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s;
`;

const DropdownWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 18.5px;
`;

const ChainsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18.5px;
`;

const ChainIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const ChainItem = styled.li<{ active: boolean }>`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ active }) => (active ? 0.5 : 1)};

  &:hover {
    opacity: ${({ active }) => (active ? 0.5 : 0.8)};
    cursor: ${({ active }) => (active ? "not-allowed" : "pointer")};
  }
`;

const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 30px;
`;
