import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConnect, useDisconnect } from "wagmi";
import { Onboarding } from "../../features/Onboarding/Onboarding";
import { useOnboarding } from "../../features/Onboarding/useOnboarding";
import { useKYC } from "../../pages/userPanel/KYC/useKYC";
import { useAppDispatch, useAppSelector } from "../../redux";
import { closeConnectWalletModal } from "../../redux/features/uiSlice";
import { defaultHomeRoute } from "../../utils/routes";
import { KycModalBody } from "../KycModalBody/KycModalBody";
import { Modal } from "../Modal/Modal";
import { SignMessageBody } from "../SignMessageBody/SignMessageBody";
import { ConnectWallet } from "./ConnectWallet";

enum ModalContent {
  ConnectingWallet,
  SignMessage,
  KycInstructions,
}

export const ConnectWalletModal = () => {
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | null>(
    null
  );
  const { isPending, connectAsync, isSuccess, connectors, isError } =
    useConnect();

  const navigate = useNavigate();

  const { disconnectAsync: discconectHook } = useDisconnect();

  const { onboardSteps, activeStepIndex } = useOnboarding();

  const { inProgressInit } = useKYC({ notMountListener: true });

  const { isOpenConnectWalletModal, isSignMessageError } = useAppSelector(
    (state) => state.ui
  );
  const dispatch = useAppDispatch();

  const {
    userData: { kyc },
    isLogged,
    preLogged,
  } = useAppSelector((state) => state.user);
  const { status: kycStatus } = { ...kyc };

  const onboardingNeeded =
    isLogged &&
    (!kycStatus || (kycStatus !== "approved" && kycStatus !== "started"));

  const handleCloseAndResetState = async () => {
    dispatch(closeConnectWalletModal());
    if (selectedConnectorId === "walletConnect") {
      window.location.reload();
    }
    await discconectHook();
  };

  const skipHandler = () => {
    dispatch(closeConnectWalletModal());
    navigate(defaultHomeRoute);
  };

  useEffect(() => {
    if (isSignMessageError) {
      handleCloseAndResetState();
    }
  }, [isSignMessageError]);

  const renderModalContent = (activeIndex: number) => {
    switch (activeIndex) {
      case 2:
        return (
          <KycModalBody
            handleModalClose={() => dispatch(closeConnectWalletModal())}
          />
        );
      case 1:
        return <SignMessageBody />;
      case ModalContent.ConnectingWallet:
      default:
        return (
          <ConnectWallet
            connectAsync={connectAsync}
            isPending={isPending}
            isError={isError}
            isSuccess={isSuccess}
            connectors={connectors}
            selectedConnectorId={selectedConnectorId}
            setSelectedConnectorId={setSelectedConnectorId}
            handleModalClose={handleCloseAndResetState}
          />
        );
    }
  };

  return (
    <Modal
      title={["Connect", "Your Wallet"]}
      description="To begin, please connect your crypto wallet. You can choose from popular options like MetaMask or WalletConnect. Your wallet will be securely linked to manage your investments."
      isOpen={isOpenConnectWalletModal}
      onClose={isLogged || preLogged ? skipHandler : handleCloseAndResetState}
      skipHandler={skipHandler}
      withOutsideClick={!(onboardingNeeded || isPending)}
      withBackdropBlur
      titleVisible={!inProgressInit}
      disableCloseBtn={isPending && selectedConnectorId !== "walletConnect"}
      steps={{
        summaryComponent: {
          visible: onboardingNeeded,
          node: <Onboarding />,
        },
        stepsData: {
          activeStepIndex,
          stepsArray: onboardSteps.map((step) => ({
            title: step?.title,
            description: step.description,
          })),
        },
      }}
    >
      {renderModalContent(activeStepIndex)}
    </Modal>
  );
};
