import { ethers } from "ethers";
import { config } from "../config";

export const AppRpcProvider = new ethers.providers.FallbackProvider(
  [
    {
      provider: new ethers.providers.JsonRpcProvider(
        config.JSON_RPC_URL_POLYGON
      ),
      priority: 3,
      weight: 5,
    },
  ],
  1
);

export const AppRpcBatchProvider = new ethers.providers.JsonRpcBatchProvider(
  config.JSON_RPC_URL_POLYGON
);

class RpcProviderManager {
  private static instance: RpcProviderManager;

  private providers: { [key: number]: ethers.providers.JsonRpcProvider } = {};

  getRpcUrlForChain: (chainId: number) => string;

  private constructor() {
    this.getRpcUrlForChain = getRpcUrlForChain.bind(this);
  }

  public static getInstance(): RpcProviderManager {
    if (!RpcProviderManager.instance) {
      RpcProviderManager.instance = new RpcProviderManager();
    }
    return RpcProviderManager.instance;
  }

  public getProvider(
    chainId: number | undefined = config.NETWORK_ID
  ): ethers.providers.JsonRpcProvider {
    if (!this.providers[chainId]) {
      const rpcUrl = this.getRpcUrlForChain(chainId);
      this.providers[chainId] = new ethers.providers.JsonRpcProvider(rpcUrl);
    }
    return this.providers[chainId];
  }
}

function getRpcUrlForChain(chainId: number): string {
  switch (chainId) {
    case 137:
      return config.JSON_RPC_URL_POLYGON;
    case 80002:
      return config.JSON_RPC_URL_POLYGON;
    case 1:
      return config.JSON_RPC_URL_ETH;
    case 11155111:
      return config.JSON_RPC_URL_ETH;
    case 42161:
      return config.JSON_RPC_URL_ARBITRUM;
    case 421614:
      return config.JSON_RPC_URL_ARBITRUM;
    case 8453:
      return config.JSON_RPC_URL_BASE;
    case 84532:
      return config.JSON_RPC_URL_BASE;
    case 43114:
      return config.JSON_RPC_URL_AVALANCHE;
    case 43113:
      return config.JSON_RPC_URL_AVALANCHE;
    case 10:
      return config.JSON_RPC_URL_OPTIMISM;
    case 11155420:
      return config.JSON_RPC_URL_OPTIMISM;
    case 56:
      return config.JSON_RPC_URL_BINANCE;
    case 97:
      return config.JSON_RPC_URL_BINANCE;
    default:
      return "";
  }
}

export default RpcProviderManager;
