import { NavLink } from "react-router-dom";
import { useAccount } from "wagmi";
import { config } from "../../../../../config";
import { routes } from "../../../../../utils/routes";
import * as S from "./NavLinks.styled";

export type NavProps = {
  toggleMobileNav?: () => void;
};

export const AdminLinks = ({ toggleMobileNav }: NavProps) => {
  const { chainId } = useAccount();
  const isBaseNetwork = chainId === config.NETWORK_ID;
  return (
    <>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.dashboard}>
          Dashboard
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.pools}>
          Pools
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink
          onClick={toggleMobileNav}
          to={routes.adminPanel.fundsWithdrawal}
        >
          Pool Funds
        </NavLink>
      </S.NavItem>
      {isBaseNetwork && (
        <S.NavItem as="li">
          <NavLink onClick={toggleMobileNav} to={routes.adminPanel.staking}>
            Staking
          </NavLink>
        </S.NavItem>
      )}
      <S.NavItem as="li">
        <NavLink
          onClick={toggleMobileNav}
          to={routes.adminPanel.feesWithdrawal}
        >
          Claim fees
        </NavLink>
      </S.NavItem>
      {isBaseNetwork && (
        <S.NavItem as="li">
          <NavLink onClick={toggleMobileNav} to={routes.adminPanel.vesting}>
            Vesting
          </NavLink>
        </S.NavItem>
      )}
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.whiteList}>
          WhiteList
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.users}>
          Users
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.admins}>
          Admins
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink
          onClick={toggleMobileNav}
          to={routes.adminPanel.loanAgreements}
        >
          Loan Agreements
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.referrals}>
          Referrals
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.messages}>
          Messages
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.adminPanel.reports}>
          Reports
        </NavLink>
      </S.NavItem>
    </>
  );
};
