import { useAccount } from "wagmi";

interface IPoolsV2Addresses {
  [key: number]: string | undefined;
}
const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string
) => {
  if (typeof variable !== "string") {
    throw Error(
      `You need to include all necessary variables inside .env file in the root of your project to run application. First missing variable: ${expectedVariableName}.`
    );
  }
  return variable;
};
const POOLS_V2_ADDRESS: IPoolsV2Addresses = {
  // Mainnets
  137: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON"
  ), // Polygon
  1: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM"
  ), // Ethereum

  // Testnets
  11155111: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM"
  ), // Ethereum Sepolia
  80002: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON"
  ), // Polygon Amoy Testnet
  42161: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_ARBITRUM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_ARBITRUM"
  ), // Arbitrum
  421614: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_ARBITRUM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_ARBITRUM"
  ), // Arbitrum Sepolia
  8453: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_BASE_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_BASE"
  ), // Base
  84532: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_BASE_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_BASE"
  ), // Base Sepolia
  43114: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_AVALANCHE_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_AVALANCHE"
  ), // Avalanche
  43113: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_AVALANCHE_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_AVALANCHE"
  ), // Avalanche Fuji
  10: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_OPTIMISM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_OPTIMISM"
  ), // Optimism
  11155420: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_OPTIMISM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_OPTIMISM"
  ), // Optimism Sepolia
  56: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_BINANCE_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_BINANCE"
  ), // Binance Smart Chain
  97: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_BINANCE_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_BINANCE"
  ), // Binance Smart Chain Testnet
};

export const usePoolsV2ContractAddress = () => {
  const { chainId } = useAccount();

  if (!chainId || !POOLS_V2_ADDRESS[chainId]) {
    return envVariableValidator(
      process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON_V2,
      "REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON"
    );
  }

  return String(POOLS_V2_ADDRESS[chainId]);
};
