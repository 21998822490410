import styled from "styled-components";
import { device } from "../../../../../../style";

export type DropdownProps = {
  targetIcon: string;
};

export const Dropdown = styled.div.withConfig({
  shouldForwardProp: (prop) => !["targetIcon"].includes(prop),
})<DropdownProps>`
  position: absolute;
  border-radius: 12px;
  height: auto;
  top: 50px;
  right: ${({ targetIcon }) => (targetIcon === "network" ? "-100px" : "-14px")};
  border: 1px solid #08a98aa3;
  background: ${({ theme }) => theme.colors.toolbar.background};
  backdrop-filter: blur(4px);
  box-shadow: 0px 0px 13.7px 0px #26a6776e;
  z-index: 100;
  right: 0;
  width: 100%;

  @media ${device.tablet} {
    width: unset;
  }

  @media ${device.laptop} {
    right: ${({ targetIcon }) => (targetIcon === "network" ? "0" : "-14px")};
  }
`;

export const DropdownMark = styled.img`
  position: absolute;
  top: -7px;
  z-index: ${({ theme }) => theme.zIndex.toolbar.dropdown};
  filter: invert(30%) sepia(64%) saturate(432%) hue-rotate(107deg)
    brightness(98%) contrast(102%);
`;
