import styled from "styled-components";
import { SmallSpinner } from "../SmallSpinner/SmallSpinner";
import { Typography } from "../Typography/Typography";
import { Theme } from "../../@types/theme";

const handleBorderType = (
  theme: Theme,
  error: string | undefined,
  warning: string | undefined,
  defaultColor: string
) => {
  if (error) return theme.colors.error;
  if (warning) return theme.colors.warning;
  return defaultColor;
};

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div<{
  error?: string;
  title?: string;
  warning?: string;
  $loading?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${({ title }) => (title ? "83px" : "60px")};

  background-color: ${({ theme }) => theme.colors.greyDark01};
  border: 1px solid
    ${({ theme, error, warning }) =>
      handleBorderType(theme, error, warning, theme.colors.greyDark)};
  border-radius: 2px;
  gap: 10px;

  padding: 0 20px;
  transition: 0.3s;
  position: relative;

  & > * {
    transition: 0.3s;
    opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
  }

  &:hover {
    border: 1px solid
      ${({ theme, error, warning, isDisabled }) =>
        !isDisabled
          ? handleBorderType(theme, error, warning, theme.colors.white033)
          : handleBorderType(theme, error, warning, theme.colors.greyDark)};
  }
  &:active {
    border: 1px solid
      ${({ theme, error, warning, isDisabled }) =>
        !isDisabled &&
        handleBorderType(theme, error, warning, theme.colors.primary)};
  }

  &:focus-within {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
    outline: 3px solid
      ${({ theme, error, warning }) =>
        error || warning
          ? "none"
          : handleBorderType(theme, error, warning, theme.colors.primary)};
  }
`;

export const Title = styled.p<{ isReversed?: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.description.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.description.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.description.letterSpacing};
  color: ${({ theme, isReversed }) =>
    isReversed ? theme.colors.white : theme.colors.grey};
  opacity: ${({ isReversed }) => (isReversed ? "0.6" : "1")};
  text-transform: uppercase;
`;

export const InputWrapper = styled.div<{ isReversed?: boolean }>`
  display: flex;
  flex-direction: ${({ isReversed }) => (isReversed ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Logo = styled.img<{ isDark?: boolean }>`
  filter: ${({ isDark }) => (isDark ? "invert(1)" : "none")};
  width: 24px;
  height: 24px;
`;

export const Label = styled(Typography.HeaderSmall)<{ color?: string }>`
  color: ${({ theme, color }) => color || theme.colors.grey};
  margin-left: 10px;
  opacity: 0.9;
`;

export const Input = styled.input<{ isReversed?: boolean; isDark?: boolean }>`
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 80%;
  opacity: 0.9;
  word-wrap: break-word;

  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.headerSmall.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.headerSmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.headerSmall.letterSpacing};
  color: ${({ isDark }) => (isDark ? "#000" : "#fff")};
  text-align: ${({ isReversed }) => (isReversed ? "left" : "right")};
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    // color: ${({ theme }) => theme.colors.grey};
    opacity: 0.9;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.9;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.9;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
  gap: 4px;
  height: 16px;
`;

export const DescriptionNoteWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;

export const Note = styled(Typography.BodySmall)`
  font-size: 11px;
`;

export const WarningMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.warning};
`;

export const ErrorMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.error};
`;

export const AvailableTitle = styled(Typography.BodySmall)`
  span {
    color: #08a98a;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const SmallButton = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.secondary};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const InputSpinner = styled(SmallSpinner)`
  position: absolute;
  opacity: 1;
  top: calc(50% - 25px / 2);
  left: calc(50% - 25px / 2);
`;
